
.text-gray-100 {
  color: #f8f9fc !important
}

.text-gray-200 {
  color: #eaecf4 !important
}

.text-gray-300 {
  color: #dddfeb !important
}

.text-gray-400 {
  color: #d1d3e2 !important
}

.text-gray-500 {
  color: #b7b9cc !important
}

.text-gray-600 {
  color: #858796 !important
}

.text-gray-700 {
  color: #6e707e !important
}

.text-gray-800 {
  color: #5a5c69 !important
}

.text-gray-900 {
  color: #3a3b45 !important
}


.bg-gradient-primary {
  background-color: #4e73df;
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  background-size: cover
}

.bg-gradient-secondary {
  background-color: #858796;
  background-image: linear-gradient(180deg, #858796 10%, #60616f 100%);
  background-size: cover
}

.bg-gradient-success {
  background-color: #1cc88a;
  background-image: linear-gradient(180deg, #1cc88a 10%, #13855c 100%);
  background-size: cover
}

.bg-gradient-info {
  background-color: #36b9cc;
  background-image: linear-gradient(180deg, #36b9cc 10%, #258391 100%);
  background-size: cover
}

.bg-gradient-warning {
  background-color: #f6c23e;
  background-image: linear-gradient(180deg, #f6c23e 10%, #dda20a 100%);
  background-size: cover
}

.bg-gradient-danger {
  background-color: #e74a3b;
  background-image: linear-gradient(180deg, #e74a3b 10%, #be2617 100%);
  background-size: cover
}

.bg-gradient-light {
  background-color: #f8f9fc;
  background-image: linear-gradient(180deg, #f8f9fc 10%, #c2cbe5 100%);
  background-size: cover
}

.bg-gradient-dark {
  background-color: #5a5c69;
  background-image: linear-gradient(180deg, #5a5c69 10%, #373840 100%);
  background-size: cover
}

.bg-gray-100 {
  background-color: #f8f9fc !important
}

.bg-gray-200 {
  background-color: #eaecf4 !important
}

.bg-gray-300 {
  background-color: #dddfeb !important
}

.bg-gray-400 {
  background-color: #d1d3e2 !important
}

.bg-gray-500 {
  background-color: #b7b9cc !important
}

.bg-gray-600 {
  background-color: #858796 !important
}

.bg-gray-700 {
  background-color: #6e707e !important
}

.bg-gray-800 {
  background-color: #5a5c69 !important
}

.bg-gray-900 {
  background-color: #3a3b45 !important
}

/*
 * BRIDGER Bootstrap overrides
 */
.btn-primary {
  --bs-btn-color: #f0f0f0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bridger-primary-active);
  --bs-btn-active-border-color: #0c4873;
  --bs-btn-hover-color: #fff;
  --bs-btn-disabled-color: #000;
}

.btn-outline-primary {
  --bs-btn-active-color: #fff;
  --bs-btn-hover-color: #fff;
}

.border-left-primary {
  border-left: .25rem solid var(--bs-primary) !important;
}

.border-bottom-primary {
  border-bottom: .25rem solid var(--bs-primary) !important;
}

.border-left-secondary {
  border-left: .25rem solid var(--bs-secondary) !important;
}

.border-bottom-secondary {
  border-bottom: .25rem solid var(--bs-secondary) !important;
}

.border-left-success {
  border-left: .25rem solid var(--bs-success) !important;
}

.border-bottom-success {
  border-bottom: .25rem solid var(--bs-success) !important;
}

.border-left-info {
  border-left: .25rem solid var(--bs-info) !important;
}

.border-bottom-info {
  border-bottom: .25rem solid var(--bs-info) !important;
}

.border-left-warning {
  border-left: .25rem solid var(--bs-warning) !important;
}

.border-bottom-warning {
  border-bottom: .25rem solid var(--bs-warning) !important;
}

.border-left-danger {
  border-left: .25rem solid var(--bs-danger) !important;
}

.border-bottom-danger {
  border-bottom: .25rem solid var(--bs-danger) !important;
}

.border-left-light {
  border-left: .25rem solid var(--bs-light) !important;
}

.border-bottom-light {
  border-bottom: .25rem solid var(--bs-light) !important;
}

.border-left-dark {
  border-left: .25rem solid var(--bs-dark) !important;
}

.border-bottom-dark {
  border-bottom: .25rem solid var(--bs-dark) !important;
}

.text-xs {
  font-size: .7rem;
}

.text-sm {
  font-size: .825rem;
}

.text-lg {
  font-size: 1.2rem
}

table.table-xs {
  > * > * > th {
    padding: 8px !important;
    line-height: 1rem;
  }

  > * > * > td {
    padding: 4px 8px !important;
    line-height: 1rem;
  }
}

table > tbody > tr.inactive > td {
  color: #999 !important;
}

.progress-sm {
  height: 0.5rem;
}

.bg-pl-le {
  background-color: var(--bg-pl-le);
}

.bg-pl-qt {
  background-color: var(--bg-pl-qt);
}

.bg-pl-qu {
  background-color: var(--bg-pl-qu);
}

.bg-pl-cu {
  background-color: var(--bg-pl-cu);
}

.fg-tt-all {
  color: var(--tt-all);
}

.fg-tt-m_email {
  color: var(--tt-m_email);
}

.fg-tt-call {
  color: var(--tt-call);
}

.fg-tt-dm {
  color: var(--tt-dm);
}

.fg-tt-video {
  color: var(--tt-video);
}

.fg-tt-video_linkedin {
  color: var(--tt-video_linkedin);
}

.fg-tt-audio {
  color: var(--tt-audio);
}

.fg-tt-other {
  color: var(--tt-other);
}

.fg-tt-meeting {
  color: var(--tt-meeting);
}

.bg-tt-all {
  background-color: var(--tt-all);
}

.bg-tt-m_email {
  background-color: var(--tt-m_email);
}

.bg-tt-call {
  background-color: var(--tt-call);
}

.bg-tt-dm {
  background-color: var(--tt-dm);
}

.bg-tt-video {
  background-color: var(--tt-video);
}

.bg-tt-video_linkedin {
  background-color: var(--tt-video_linkedin);
}

.bg-tt-audio {
  background-color: var(--tt-audio);
}

.bg-tt-other {
  background-color: var(--tt-other);
}

.bg-tt-meeting {
  background-color: var(--tt-meeting);
}


.text-bg-tt-all {
  background-color: var(--tt-all-w);
  color: var(--tt-all);
}

.text-bg-tt-m_email {
  background-color: var(--tt-m_email-w);
  color: var(--tt-m_email);
}

.text-bg-tt-call {
  background-color: var(--tt-call-w);
  color: var(--tt-call);
}

.text-bg-tt-dm {
  background-color: var(--tt-dm-w);
  color: var(--tt-dm);
}

.text-bg-tt-video {
  background-color: var(--tt-video-w);
  color: var(--tt-video);
}

.text-bg-tt-video_linkedin {
  background-color: var(--tt-video_linkedin-w);
  color: var(--tt-video_linkedin);
}

.text-bg-tt-audio {
  background-color: var(--tt-audio-w);
  color: var(--tt-audio);
}

.text-bg-tt-other {
  background-color: var(--tt-other-w);
  color: var(--tt-other);
}

.text-bg-tt-meeting {
  background-color: var(--tt-meeting-w);
  color: var(--tt-meeting);
}

.text-bg-primary {
  color: white !important;
}

.form-control {
  font-size: 13px;
  --bs-border-color: #aaa;
}

.btn-google {
  --bs-btn-color: #fff;
  --bs-btn-bg: #DB4437;
  --bs-btn-border-color: #DB4437;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bd2e22;
  --bs-btn-hover-border-color: #92241a;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #92241a;
  --bs-btn-active-border-color: #bd2e22;
  --bs-btn-disabled-color: #444;
  --bs-btn-disabled-bg: #eb958d;
  --bs-btn-disabled-border-color: #eb958d;
}

.btn-microsoft {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00a2ed;
  --bs-btn-border-color: #00a2ed;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0288c6;
  --bs-btn-hover-border-color: #016a9a;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #016a9a;
  --bs-btn-active-border-color: #0288c6;
  --bs-btn-disabled-color: #444;
  --bs-btn-disabled-bg: #8ec9e5;
  --bs-btn-disabled-border-color: #8ec9e5;
}

.form-control {
  font-size: 13px;
  --bs-border-color: #aaa;
}

.text-bg-info {
  color: white !important;
}

.alert {
  --bs-alert-padding-y: 1ex;
}

.alert-dismissible .btn-close {
  padding: 1.25ex;
}

.card {
  &.card-xxs {
    width: 140px;
  }

  &.card-xs {
    width: 220px;
  }

  &.card-sm {
    width: 300px;
  }

  &.card-md {
    width: 500px;
  }

  &.card-md2 {
    width: 600px;
  }

  &.card-lg {
    width: 800px;
  }

  &.card-xl {
    width: 1140px;
  }
}
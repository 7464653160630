:root {
  --bridger-primary: #1684d3;
  --bridger-primary-active: #0f639f;
  --bridger-pale-blue: #3cabfb;
  --danger: #bc0911;

  --bg-pl-le: #e7eff5;
  --bg-pl-qt: #cfdfeb;
  --bg-pl-qu: #b7d0e2;
  --bg-pl-cu: #9fc0d8;

  --tt-all: #666;
  --tt-m_email: #BE4F15;
  --tt-call: #78206E;
  --tt-dm: #09769F;
  --tt-video: #11501B;
  --tt-video_linkedin: #215E98;
  --tt-audio: #7030A0;
  --tt-other: #3A3A3A;
  --tt-meeting: #3A3A3A;

  // https://maketintsandshades.com/#3A3A3A
  --tt-all-w: #ededed;
  --tt-m_email-w: #f9ede8;
  --tt-call-w: #f2e9f1;
  --tt-dm-w: #e6f1f5;
  --tt-video-w: #e7eee8;
  --tt-video_linkedin-w: #e9eff5;
  --tt-audio-w: #f1eaf6;
  --tt-other-w: #ebebeb;
  --tt-meeting-w: #ebebeb;

  /* size */
  --select2-single-height: 34px;
  --select2-multiple-height: 34px;

  --select2-selection-line-height: 34px;
  --select2-selection-border-radius: 6px;
  --select2-selection-border-color: #ced4da;
  --select2-selection-focus-border-color: #a8adb2;
  //--select2-selection-padding: 2px 0 2px 8px;
  --select2-selection-multiple-gap: 0px;
  --select2-selection-multiple-padding: 1px;

  --select2-search-border-color: #aaa;
}

.select2-selection__choice {
  padding: unset;
}

$primary: #1684d3;
$danger: #bc0911;

@import "bootstrap/scss/bootstrap";
@import "ngx-toastr/toastr";
@import "custom-theme";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "./bridger.scss";
@import "./bridger-bootstrap.scss";
@import "./bridger-mat-reset.scss";

.mat-mdc-dialog-container .mdc-dialog__title {
  --mdc-dialog-subhead-color: #fff;
  --mdc-dialog-subhead-size: 16px;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-size: 14px;
}

button.mat-mdc-tab-header-pagination {
  display: none !important;
}

.mat-mdc-select {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 13px;
  letter-spacing: normal;
}

.mat-mdc-tab-labels {
  display: flex;
  margin: 10px;

  .mdc-tab__text-label {
    font-family: Roboto, Helvetica Neue, sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: normal;
  }

  .mat-mdc-tab.mdc-tab {
    margin-left: 1px;
    padding-left: 8px;
    padding-right: 8px;
    height: 36px !important;
    border-radius: 10px 10px 0 0;
    background-color: #fff;

    &.mdc-tab--active {
      background-color: #42acf3;
    }
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #fff;
  }

  .mdc-tab-indicator__content--underline {
    --mdc-tab-indicator-active-indicator-color: var(--bridger-primary-active);
  }
}

/**
 * time picker fixes
 */
::ng-deep .ngx-mat-timepicker-control {
  width: 80px !important;
  min-width: 80px !important;

  .arrows-wrap > .arrow {
    height: 16px;
  }

  .mat-mdc-form-field-infix {
    padding: 0 !important;
    line-height: 32px;
    min-height: 32px !important;
    height: 32px !important;
  }
}

/**
 * Date Picker
 */
.mat-datepicker-input {
  border: solid 1px #ced4da;
  padding-left: 0.5rem;
}

.mat-datepicker-toggle {
  position: absolute;
  top: -7px;
  right: 10px;
}

.mat-calendar {
  color: white;
  background-color: var(--bridger-pale-blue);
  border-radius: 4px;
}

::ng-deep .mat-calendar-body-cell-content {
  background-color: #fff;
  border-radius: 2px !important;

  &.mat-calendar-body-today {
    background-color: var(--bridger-primary-active);
    color: #fff;
  }

  &.mat-calendar-body-selected {
    background-color: #000;
    color: #fff;
  }
}

::ng-deep #smallCalendar .mat-calendar-header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

::ng-deep .mat-calendar-controls .mat-mdc-button:not(:disabled) {
  color: #fff;
  letter-spacing: normal;
}


input.ng-invalid.ng-touched {
  border: 1px solid red;
}

.row.form-group {
  margin-bottom: 1ex;
  margin-top: 1ex;
  align-items: baseline;
}

html, body {
  //height: 100%;
  background: #e8ebef;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

td.db-date {
  white-space: nowrap;
  padding-left: 1ex;
  padding-right: 1ex;
  font-size: 12px;
  color: #3a3a3a;
}

/* 012296  0958be  20aae3 04d1f5
 red bc0911
*/
.rounded-12 {
  border-radius: 4rem;
}

.title_new {
  outline: 0;
  color: #ffffff;
  background-color: #04d1f5 !important;
  border-color: #04d1f5 !important;
}

.teal-100 {
  background-color: #b2dfdb !important;
}

.bridger-100 {
  background-color: #a2d4ff !important;
}

.green-100 {
  background-color: #87e98c;
}

.red-100 {
  background-color: #ffcdd2 !important;
}

.orange-100 {
  background-color: #ffe0b2 !important;
}

.yellow-100 {
  background-color: #ffffe0 !important;
}

.btn-right {
  width: 80px;
  height: 28px;
  border-radius: 3px;
  float: right;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #6a6a6a;
  border-radius: 10px;
}

.wrapper-body {
  width: 100%;
  float: left;
  background: #e8ebef;
  font-size: 13px;
}

div.sidebar {
  border-radius: 5px;
  background: var(--bridger-primary);
  height: 80vh;
  overflow: scroll;

  &.with_toolbar {
    height: calc(100vh - 200px);
  }
}

.right_sidebar {
  width: calc(100% - 150px);
  float: right;
  margin-top: 60px;
  height: 100%;
  position: relative;
  transition: 0.3s;
}

.padding_right {
  padding: 1ex 1ex 1ex 2ex;
  width: 100%;
  float: left;
}

.content_std {
  width: 100%;
  background: #fff;
  padding: 15px 15px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 3px #d2d2d2;
}

.content_right {
  position: relative;
  width: 100%;
  float: left;
  background: #fff;
  padding: 15px 15px 30px;
}

.title {
  width: 100%;
  border-radius: 5px 5px 0 0;
  background-color: #1684d3;
  font-size: 16px;
  padding: 10px 15px;
  color: white;
  position: relative;
  z-index: 1;
}

.submitBtn {
  background-color: var(--bs-primary);
  display: block;
  border: none;
  height: 42px;
  width: 100%;
  text-align: center;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 1rem;
  transition: 0.3s;
  padding-left: 10px;
  padding-right: 10px;
}

.submitBtn:hover {
  transition: 0.3s;
  background-color: var(--bridger-primary-active);
}

figure {
  margin: 0;
}

a {
  text-decoration: none;
  color: #0b72bc;
}

.fc .fc-button {
  text-transform: capitalize !important;
  background-color: #0b72bc;
  border: none;
}

.errorSpn {
  color: red;
  font-size: 80%;
  position: absolute;
  bottom: -17px;
}

.box___title h4 {
  margin: 0;
  padding: 8px;
  background-color: #1684d3;
  color: #fff;
  text-align: center;
  display: block;
  font-size: 16px;
  border-radius: 4px 4px 0 0;
}

.box___title {
  width: 100%;
  float: left;
  position: relative;
  border: solid 1px #ced4da;
  height: 100%;
  border-radius: 5px 5px 5px 5px;
  background-color: #fff;
}

.text_content_box {
  width: 100%;
  float: left;
  padding: 15px;
  position: relative;
  min-height: 250px;
}

.text_content_box p.todoEmpty {
  margin-bottom: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: #a4aebb;
}

.table-gray th {
  background-color: #f2f2f2;
}

.bridger-dialog-header {
  display: flex;
  align-items: center;
  background-color: var(--bridger-primary);
  height: 48px;
}

.bridger-dialog-header h1 {
  font-size: 16px;
  color: #fff;
  margin: 0;
  padding-top: 0;
  padding-bottom: 16px;
}

.bridger-dialog-header .cenBtn {
  padding: 0 0 0 3px;
  margin-left: auto;
  margin-right: 1rem;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
  background-color: #fff;
  border-radius: 50%;
  font-size: 24px;
  color: var(--bridger-primary) !important;
}

.clickable {
  cursor: pointer;
}

.page-back {
  float: right;
  font-size: 14px;
  cursor: pointer;
}

.page-back::before {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  content: "";
  display: inline-block;
  margin-top: -4px;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  opacity: 0.8;
}

label {
  font-weight: normal !important;
}

.video__list {
  margin: 0;
  padding: 0;
}

.video__list li {
  display: flex;
  background-color: #deecf7;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  align-items: center;
}

.video__list li p {
  margin-bottom: 0;
}

.video__list li a {
  margin-left: auto;
}

.video__list li a i {
  font-size: 30px;
  color: blue;
}

p.video_head {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 13px;
  color: #1e6fab;
  padding: 7px 0;
}

.text_content_box_video h3 {
  margin: 0;
  text-align: center;
  border-bottom: solid 1px #dfdfdf;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 5px;
  padding-top: 8px;
}

button.button-common {
  background-color: #0d6efd !important;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .TabBar {
    padding: 10px 5px 9px;
  }
}

.errorSpn {
  position: inherit;
  bottom: inherit;
}

.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid #42a948; /* green */
}

.ng-invalid:not(form) {
  border-left: 5px solid #a94442; /* red */
}

.btn-std {
  min-width: 72px;
}

.btn-xs {
  padding: 0 5px;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px;
}

.btn-xs:disabled {
  color: inherit;
  background-color: transparent;
  opacity: .35;
}

.btn-group-xs > .btn {
  padding: 0 5px;
  font-size: 12px;
  line-height: 18px;
  border-radius: 4px;
}

.btn-group-xs > .btn-label {
  padding: 0 5px;
  font-size: 12px;
  line-height: 18px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.btn-group-xs > .btn:disabled {
  color: inherit;
  background-color: transparent;
  opacity: .35;
}

.Reminders {
  margin-left: 1ex;
  margin-right: 1ex;
}

ng-select2 {
  width: 100%;
}

.tab-container {
  margin: 0 16px 10px;
  color: white;
}

.break-anywhere {
  overflow-wrap: anywhere;
}

/** switchBox **/

.switchBox {
  -webkit-appearance: none;
  appearance: none;
  height: 23px;
  width: 46px;
  padding-left: 23px;
  margin: -1px 0;
  border-radius: 37px;
  background: radial-gradient(
                  circle 7px,
                  #0535e1bf 100%,
                  transparent calc(100% + 1px)
  ) #4c4c4c87 -10px;
  transition: 0.3s ease-in-out;
}

.switchBox::before {
  content: "OFF";
  font: bold 08px/22px Verdana;
  color: rgb(34, 34, 34);
}

.switchBox:checked {
  padding-left: 8px;
  background-color: rgb(226, 232, 238);
  background-position: 14px;
}

.switchBox:checked::before {
  content: "ON";
}

/** switchOnOff **/

.switchOnOff {
  display: inline-block;
  height: 23px;
  width: 46px;
  padding-left: 22px;
  margin: 0;
  border-radius: 12px;
  background: radial-gradient(
                  circle 7px,
                  #0535e1bf 100%,
                  transparent calc(100% + 1px)
  ) #4c4c4c87 -11px;
}

.switchOnOff::before {
  content: "OFF";
  font: bold 08px/22px Verdana;
  color: rgb(34, 34, 34);
}

.switchOnOff.active {
  width: 46px;
  padding-left: 8px;
  background-color: rgb(226, 232, 238);
  background-position: 11px;
}

.switchOnOff.active::before {
  content: "ON";
}

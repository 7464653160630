.bridger-header {
  font-size: 1.2rem;
  font-weight: bold;
  color: #3a3a3a;
}

.bridger-loader {
  width: 100%;
  height: 4px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.15);
  overflow: hidden;
}

.bridger-loader::after {
  content: '';
  width: 192px;
  height: 4px;
  background: #a7dfff;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animLoader 4s linear infinite;
}

@keyframes animLoader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}

.square-loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  //color: #fff;
  color: var(--bridger-primary-active);

}

.square-loader:before,
.square-loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}

.square-loader:after {
  color: #FF3D00;
  transform: rotateY(70deg);
  animation-delay: .4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0 0 0 currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0 currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

.icon-std {
  width: 17.5px;
  vertical-align: text-top;
}

.icon-sm {
  width: 17.5px;
  vertical-align: text-top;
}

span.b-icon {
  vertical-align: text-top;
  display: inline-block;
  background-size: 18px;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;

  &.b-crowd {
    background-image: url("assets/img/crowd.svg");
  }

  &.b-office {
    background-image: url("assets/img/news-office.svg");
  }

  &.b-users {
    background-image: url("assets/img/group.svg");
  }
}

a:hover > span.b-icon {
  filter: drop-shadow(0px 0px 2px #fff);
}

.mw-200 {
  max-width: 200px;
}

.mw-300 {
  max-width: 300px;
}

.mw-400 {
  max-width: 400px;
}

.mw-500 {
  max-width: 500px;
}

.mw-600 {
  max-width: 600px;
}

.mw-800 {
  max-width: 800px;
}
